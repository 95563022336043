export const createRangeArray = (start, end) => {

	if (typeof start !== "number" || typeof end !== "number") return;
	if (start > end) return;

	const rangeArray = [];
	for (let i = start; i <= end; i++) {
		rangeArray.push(i);
	}

	return rangeArray;
};