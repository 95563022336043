import axios from "axios";

const axiosInterceptor = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
});

axiosInterceptor.interceptors.request.use(
	(request) => {
		if (!request.headers) {
			request.headers = {};
		}
		return request;
	},
	(error) => {
		return Promise.reject(error);
	},
);

axiosInterceptor.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		return Promise.reject(error);
	},
);

export default axiosInterceptor;
