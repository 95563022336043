import React, { 
	useState,
	useContext,
} from "react";
import {
	Box,
	Grid,
	Typography,
	Avatar,
	Paper,
	Button,
	FormControlLabel,
	Checkbox,
	Table,
	TableContainer,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	CircularProgress
} from "@mui/material";
import "./PricingCalculator.scss";
import {
	Formik,
	Form,
} from "formik";
import * as Yup from "yup";
import { DataContext } from "../../App";
import { createRangeArray } from "../../utils/array";
import { countries } from "../../utils/countries";
import { InputSlider } from "../Shared/Slider/Slider";
import { TextfieldFormik } from "../Shared/TextField/TextField";
import DropDown from "../Shared/DropDown/DropDown";
import { capitalizeFirstLetterOfEachWord } from "../../utils/text";
import DiscountIcon from "@mui/icons-material/Celebration";

export default function PricingCalculator() {

	const context = useContext(DataContext);
	const [state, setState] = useState({
		isLoading: false,
		isPricingFormSubmitted: false,
		isPricingQuoteVisible: false,
		isConfirmationVisible: false,
		pricingFormData: {},
		pricingFormResponseData: {},
		sellerFormData: {},
		sellerFormDataResponse: {},
		errorMessage: "",
	});

	const INITIAL_FORM_STATE = {
		monthly_orders: 1,
		not_shipping: false,
		country: "",
		picks_per_order: "",
		average_weight: "",
		first_name: "",
		last_name: "",
		email: "",
		company_website: "",
	};

	const ordersForVolumeDiscount = 1000;

	const FORM_VALIDATION = Yup.object().shape({
		...(!state.isPricingFormSubmitted ? {
			country: Yup.string()
				.required("Please select a country"),
			picks_per_order: Yup.number()
				.required("Please select your average picks per order"),
			average_weight: Yup.number()
				.required("Please select the average weight of SKU/Product"),
		} : {
			first_name: Yup.string()
				.required("Please add your first name"),
			last_name: Yup.string()
				.required("Please add your last name"),
			email: Yup.string()
				.email("Please enter a valid email address")
				.required("Please confirm email."),
			company_website: Yup.string()
				.required("Please enter a company website"),
		}),
	});

	const picksPerOrderData  = createRangeArray(1, 10);
	const weightOfSKUorProductData  = createRangeArray(1, 30);

	const handleSliderChange = (setFieldValue, newValue) => {
		setFieldValue("monthly_orders", newValue, false);
		setFieldValue("not_shipping", newValue === 0 ? true : false, false);
	};

	const calculateStep = (value) => {
		if (value <= 5000) {
			return 100;
		} else {
			return 1000;
		}
	};

	const handleCheckboxChange = (setFieldValue, event) => {
		setFieldValue("not_shipping", event.target.checked ? true : false, false);
		setFieldValue("monthly_orders", event.target.checked ? 0 : 1, false);
	};

	const countriesPricingAvailable = ["united kingdom", "germany", "spain", "netherlands", "united states"];
	const onSubmitForm = async (values) => {

		if (!values) return;

		setState(previousState => {
			return {
				...previousState,
				isLoading: true,
			};
		});

		if (!state.isPricingFormSubmitted) {
			if (countriesPricingAvailable.includes(values.country.toLowerCase())) {
				setState(previousState => {
					return {
						...previousState,
						isPricingQuoteVisible: true,
					};
				});
			}

			await context.dataProvider.createPricingRequest(values)
				.then((res) => {
					setState(previousState => {
						return {
							...previousState,
							pricingFormData: values,
							pricingFormResponseData: res.data,
							isPricingFormSubmitted: true,
						};
					});
				})
				.catch(() => {
					setState(previousState => {
						return {
							...previousState,
							isPricingFormSubmitted: false,
							isPricingQuoteVisible: false,
						};
					});				
				});

			setState(previousState => {
				return {
					...previousState,
					isLoading: false,
				};
			});	

		}

		if (state.isPricingFormSubmitted) {

			setState(previousState => {
				return {
					...previousState,
					isLoading: true,
				};
			});

			await context.dataProvider.createQuoteRequest(values, state.pricingFormData)
				.then((res) => {
					setState(previousState => {
						return {
							...previousState,
							sellerFormData: values,
							sellerFormDataResponse: res.data,
							isConfirmationVisible: true,
							isPricingQuoteVisible: false,
							errorMessage: "",
						};
					});
				})
				.catch(() => {
					setState(previousState => {
						return {
							...previousState,
							isConfirmationVisible: false,
							errorMessage: "Error: please email us for a quote sales@bezos.ai",
						};
					});				
				});

			setState(previousState => {
				return {
					...previousState,
					isLoading: false,
				};
			});	

		}

	};

	const PageHeading = () => {
		return (
			<Box>
				<Grid container mt={1}>
	
					<Grid item xs={12} container mb={2}>
	
						<Grid item xs>
							<Typography component="h1" variant="h5">
								Pricing Calculator
							</Typography>
						</Grid>
	
						<Grid item>
							<a href="https://bezos.ai" title="Bezos.ai" target="_blank" rel="noreferrer">
								<Avatar
									src={"/logo/bezos-logo.svg"}
									alt="Bezos.ai"
									title="Bezos.ai"
									variant="square"
									sx={{ width: "140px", height: "30px" }}
								/>
							</a>
						</Grid>
	
					</Grid>
	
				</Grid>
			</Box>
		);
	};
	
	const PricingForm = ({ values, setFieldValue }) => {
		return (
			<Box>
				<Grid container spacing={1}>

					<Grid item xs={12} mb={2}>

						<Grid item xs="auto">
							<Typography component="p" variant="body">
								Add your details to get an instant understanding of your per order costs with Bezos.AI
							</Typography>
						</Grid>

						<Grid item xs={12} p={2}>

							<Paper sx={{ p: 2, width: "100%", mb: 2 }} elevation={2}>
								<Typography component="h2" variant="h6" mb={1}>
									Number of Monthly Orders
								</Typography>
								<Box>
									<InputSlider
										id="monthly_orders"
										name="monthly_orders"
										initialValue={values.monthly_orders}
										min={0}
										max={20000}
										step={calculateStep(values.monthly_orders)}
										value={values.monthly_orders}
										onChange={(event) => handleSliderChange(setFieldValue, event)}
									/>
								</Box>
								<FormControlLabel control={
									<Checkbox
										id="not_shipping"
										name="not_shipping"
										checked={values.not_shipping}
										onChange={(newValue) => handleCheckboxChange(setFieldValue, newValue)}
									/>
								} 
								label="I'm new/not shipping" />
							</Paper>

							<Paper sx={{ p: 2, width: "100%", mb: 2 }} elevation={2}>
								<Typography component="h2" variant="h6">
									Country where you would like to fulfil most of your orders
								</Typography>
								<DropDown
									id="country"
									name="country"
									label="Select your country"
									select={true}
									value={values.country}
									data={countries.sort((a, b) => a.name > b.name ? -1 : 1).map((c) => ({
										key: c.platform_id,
										value: c.name,
										name: c.name
									}))}
									required={true}
								/>
							</Paper>

							<Paper sx={{ p: 2, width: "100%", mb: 2 }} elevation={2}>
								<Typography component="h2" variant="h6">
									Average picks per order
								</Typography>
								<DropDown
									id="picks_per_order"
									name="picks_per_order"
									label="Select your average picks per order"
									select={true}
									value={values.picks_per_order}
									data={picksPerOrderData.map((p) => ({
										key: p,
										value: p,
										name: p
									}))}
									required={true}
								/>
							</Paper>

							<Paper sx={{ p: 2, width: "100%", mb: 2 }} elevation={2}>
								<Typography component="h2" variant="h6">
									Average weight of a SKU/Product
								</Typography>
								<DropDown
									id="average_weight"
									name="average_weight"
									label="Select the average weight of SKU/Product"
									select={true}
									value={values.average_weight}
									data={weightOfSKUorProductData.map((p) => ({
										key: p,
										value: p,
										name: `${p}KG`
									}))}
									required={true}
								/>
							</Paper>

							<Button
								variant="contained"
								color="primary"
								type="submit"
							>
								Calculate Prices {state.isLoading && <CircularProgress size={20} color="statusbox" sx={{ ml: 1 }}/>}
							</Button>

						</Grid>

					</Grid>

				</Grid>
			</Box>
		);
	};

	const ResponseForm = () => {
		return (
			<Box>
				<Grid container spacing={1}>

					<Grid item xs={12} mb={0}>

						<Paper sx={{ p: 2, width: "100%", mb: 2 }} elevation={2}>
							<Typography component="h2" variant="h6" mb={1}>
								To send and fulfil your example order next day our prices start from:
							</Typography>
							<Typography component="h2" variant="h6" mb={1} align="center" className="per-order-price">
								{state.pricingFormResponseData.currency}{state.pricingFormResponseData.price_per_order.toFixed(2)} per order
							</Typography>
							<Typography component="p" variant="body" mb={1} sx={{ fontStyle: "italic" }}>
								This has been calculated using our standard rates and is composed of 1x first pick, {(state.pricingFormData.picks_per_order > 1 ? `${state.pricingFormData.picks_per_order - 1}x additional pick${state.pricingFormData.picks_per_order > 2 ? "s" : ""}` : "no additional picks")} and sending on {state.pricingFormResponseData.courier_service_name}.
							</Typography>

							<TableContainer sx={{ overflow: "auto", mb:2 }} component={Paper}>
								<Table stickyHeader>
									<TableHead>
										<TableRow>
											<TableCell>
												Line Item
											</TableCell>
											<TableCell>
												Price
											</TableCell>
											<TableCell>
												Description
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										<TableRow>
											<TableCell>
												First pick
											</TableCell>
											<TableCell>
												{state.pricingFormResponseData.currency}{state.pricingFormResponseData.first_pick_price.toFixed(2)}
											</TableCell>
											<TableCell>
												First pick is the cost to pick the first item in the order
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell>
												Additional pick
											</TableCell>
											<TableCell>
												{state.pricingFormResponseData.currency}{state.pricingFormResponseData.additional_pick_price.toFixed(2)}
											</TableCell>
											<TableCell>
												Additional pick is the cost for picking the 2nd, 3rd, etc items in the order
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell>
												{state.pricingFormResponseData.courier_service_name}
											</TableCell>
											<TableCell>
												{state.pricingFormResponseData.currency}{state.pricingFormResponseData.shipping_price.toFixed(2)}
											</TableCell>
											<TableCell>
											</TableCell>
										</TableRow>					
									</TableBody>
								</Table>
							</TableContainer>

							<Typography component="p" variant="body" mb={1} sx={{ fontStyle: "italic" }}>
								For more tailored rates please request a full quote.
							</Typography>
						</Paper>

					</Grid>

				</Grid>
			</Box>
		);
	};

	const VolumeBasedDiscount = ({ orders }) => {

		if (typeof(orders) !== "number" || orders < ordersForVolumeDiscount) return;

		return (
			<Box>
				<Grid container spacing={1}>
					<Grid item xs={12}>
						<Paper sx={{ p: 1, width: "100%", mb: 2 }} elevation={2} className="voucher">
							<Box className="wrap">
								<Typography component="h2" variant="h6">
									<DiscountIcon sx={{ mr: 1 }} /> Volume Based Discount <DiscountIcon sx={{ ml: 1 }}  />
								</Typography>
								<Typography component="p" variant="body" mb={1}>
									Good News, as you are doing <Box component="span" sx={{ fontWeight: "bold", textDecoration: "underline" }}>{orders}</Box> orders per month, you qualify for volume based discounts.
								</Typography>
								<Typography component="p" variant="body" mb={1} sx={{ fontStyle: "italic" }}>
									Request a full quote to find out more. 
								</Typography>
							</Box>
						</Paper>
					</Grid>
				</Grid>
			</Box>
		);
	};

	const RequestQuoteForm = () => {
		return (
			<Box>
				<Grid container spacing={1}>

					<Grid item xs={12} mb={2}>

						<Paper sx={{ p: 2, width: "100%", mb: 2 }} elevation={2}>

							<Typography component="h2" variant="h6" mb={1} sx={{ fontWeight: 900 }}>
								Request a full quote?
							</Typography>

							{state.isPricingQuoteVisible &&
								<Typography component="p" variant="body" mb={1}>
									Please provide your details below and a member of our team will contact you to share our full pricing.
								</Typography>
							}

							{!state.isPricingQuoteVisible &&
								<Typography component="p" variant="body" mb={1}>
									For our <Box component="span" sx={{ fontWeight: "bold" }}>{capitalizeFirstLetterOfEachWord(state.pricingFormData.country.toLowerCase())}</Box> warehouse we provide bespoke pricing. Please fill your details below and a member of the team will reach out to provide you with a bespoke quote.
								</Typography>
							}

							<TextfieldFormik 
								name="first_name"
								label="First Name"
								type="string"
								required							
							/>
							<TextfieldFormik 
								name="last_name"
								label="Last Name"
								type="string"
								required
							/>
							<TextfieldFormik 
								name="email"
								label="Email"
								type="string"
								required
							/>
							<TextfieldFormik 
								name="company_website"
								label="Company Website"
								type="string"
								required
							/>
						</Paper>

						<Button
							variant="contained"
							color="primary"
							type="submit"
						>
							Request Quote {state.isLoading && <CircularProgress size={20} color="statusbox" sx={{ ml: 1 }}/>}
						</Button>

					</Grid>

				</Grid>
			</Box>
		);
	};

	const Confirmation = () => {
		return (
			<Box>
				<Grid container spacing={1}>

					<Grid item xs={12} mb={2}>

						<Paper sx={{ p: 2, width: "100%", mb: 2, minHeight: "600px" }} elevation={2}>

							{!state.errorMessage &&
								<>
									<Typography component="h2" variant="h6" mb={1} className="success" align="center">
										Success
									</Typography>
									<Typography component="p" variant="body" mb={1} align="center">
										Thank you for submitting your quote {state.sellerFormData.first_name} {state.sellerFormData.last_name}, a member of the team will contact you shortly.
									</Typography>
									<Typography component="p" variant="body" mb={1} className="italic" align="center">
										{`Ref ${state.sellerFormDataResponse.id}`}
									</Typography>
								</>
							}

							{state.errorMessage &&
								<>
									<Typography component="h2" variant="h6" mb={1} className="error" align="center">
										Error
									</Typography>
									<Typography component="p" variant="body" mb={1} align="center">
										{state.errorMessage}
									</Typography>								
								</>
							}

							<Box className="centered-box" mt={5}>
								<Button
									variant="contained"
									color="primary"
									onClick={() => (window.location.href = process.env.REACT_APP_API_WEBSITE)}
								>
									Restart
								</Button>
							</Box>

						</Paper>

					</Grid>

				</Grid>
			</Box>
		);
	};

	return (
		<Box className="wrapper">
			<Box id="pricing-calculator">

				<PageHeading />

				<Formik
					initialValues={{
						...INITIAL_FORM_STATE
					}}
					enableReinitialize // disables validation on page load
					validateOnChange={false}
					validateOnBlur={true}
					validationSchema={FORM_VALIDATION}
					onSubmit={(values) => onSubmitForm(values)}
				>
					{({ values, setFieldValue }) => (
						<Form
							noValidate
							autoComplete="off"
						>
							{!state.isPricingFormSubmitted &&
								<PricingForm values={values} setFieldValue={setFieldValue} />
							}
							
							{state.isPricingFormSubmitted && state.isPricingQuoteVisible &&
								<ResponseForm />
							}

							{state.isPricingFormSubmitted && !state.isConfirmationVisible &&
								<>
									<VolumeBasedDiscount orders={state.pricingFormData.monthly_orders} />
									<RequestQuoteForm values={values} />
								</>
							}
							
							{state.isConfirmationVisible &&
								<Confirmation />
							}

						</Form>
					)}
				</Formik>

			</Box>
		</Box>
	);

}
