import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Slider from "@mui/material/Slider";
import { formatNumber } from "../../../utils/integer";

export const InputSlider = ({
	value,
	min,
	max,
	step,
	onChange,
	disabled,
}) => {

	const handleSliderChange = (event, newValue) => {
		if (onChange) onChange(newValue);
	};

	return (
		<Box>
			<Grid container alignItems="center">
				<Grid item>
				</Grid>
				<Grid item xs>
					<Slider
						value={value}
						onChange={handleSliderChange}
						min={min}
						max={max}
						step={step}
						aria-labelledby="input-slider"
						disabled={disabled}
						sx={{ ml:1 }}
					/>
				</Grid>
				<Grid item sx={{ ml:5 }}>
					<Typography className="slider-value">
						{formatNumber(value)}{value === max && "+"}
					</Typography>
				</Grid>
			</Grid>
		</Box>
	);
};
