import { createTheme } from "@mui/material/styles";

export const LightTheme = createTheme({
	palette: {
		mode: "light",
		primary: {
			main: "rgba(60,184,121,1)",
			contrastText: "rgba(255,255,255,1)",
		},
		secondary: {
			main: "rgba(60,184,121,1)",
		},
		tertiary: {
			main: "rgba(0,0,0,1)",
		},
		destructive: {
			main: "rgba(161,51,51,1)",
			contrastText: "rgba(255,255,255,1)",
		},
		text: {
			primary: "rgba(0,0,0,0.87)",
		},
		appbar: {
			main: "rgba(49,74,131,1)",
		},
		statusbox: {
			main: "rgba(255,255,255,1)",
		},
		background: {
			main: "rgba(243, 246, 248,1)"
		}
	},
	components: {
		MuiButton: {
			styleOverrides: {
				outlined: {
					backgroundColor: "rgba(255,255,255,1)",
				},				
			},
		},
		MuiTableCell: {
			styleOverrides: {
				head: { 
					backgroundColor: "rgba(243, 246, 248, 1)",
				},
			},
		},
	},
});