import React, {
	useMemo,
	useRef
} from "react";
import "./scss/main.scss";
import { LightTheme } from "./utils/baseTheme";
import {
	ThemeProvider,
	StyledEngineProvider,
	Box,
} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import DataProvider from "./dataprovider/dataProvider";
import axiosInterceptor from "./utils/axiosInterceptor";
import PricingCalculator from "./components/PricingCalculator/PricingCalculator";

export const DataContext = React.createContext();

function App() {

	const dataProvider = useRef(null);
	useMemo(() => {
		dataProvider.current = new DataProvider(axiosInterceptor);
	}, []);

	return (
		<DataContext.Provider value={{ dataProvider: dataProvider.current }}>
			<StyledEngineProvider injectFirst>
				<ThemeProvider theme={LightTheme}>
					<Box 
						sx={{ 
							minHeight: "100vh",
							minWidh: "100vw",
						}}
					>
						<CssBaseline />
						<Box>
							<PricingCalculator />
						</Box>
					</Box>
				</ThemeProvider>
			</StyledEngineProvider>
		</DataContext.Provider>
	);
}

export default App;
