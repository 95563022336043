const jsonContentTypeOptions = { headers: { "Content-Type": "application/json" } };

export default class DataProvider {
	constructor(requestProvider) {
		this.requestProvider = requestProvider;
	}

	createPricingRequest = async (formData) => {
		const body = {
			average_weight: formData.average_weight,
			country: formData.country,
			monthly_orders: formData.monthly_orders,
			picks_per_order: formData.picks_per_order,
		};
		return this.requestProvider.post("/v1/indicative-price", body, jsonContentTypeOptions);
	};

	createQuoteRequest = async (sellerData, formData) => {
		const body = {
			company_website: sellerData.company_website,
			email: sellerData.email,
			first_name: sellerData.first_name,
			last_name: sellerData.last_name,
			pricing_details: {
				average_weight: formData.average_weight,
				country: formData.country,
				monthly_orders: formData.monthly_orders,
				picks_per_order: formData.picks_per_order,
			}
		};
		return this.requestProvider.post("/v1/quote", body, jsonContentTypeOptions);
	};

}
