export const countries = [
	{
		"platform_id": 1,
		"name": "UNITED KINGDOM",
	},
	{
		"platform_id": 5,
		"name": "UNITED STATES",
	},
	{
		"platform_id": 6,
		"name": "AUSTRIA",
	},
	{
		"platform_id": 7,
		"name": "BELGIUM",
	},
	{
		"platform_id": 8,
		"name": "CZECH REPUBLIC",
	},
	{
		"platform_id": 11,
		"name": "FRANCE",
	},
	{
		"platform_id": 12,
		"name": "GERMANY",
	},
	{
		"platform_id": 15,
		"name": "NETHERLANDS",
	},
	{
		"platform_id": 16,
		"name": "POLAND",
	},
	{
		"platform_id": 18,
		"name": "SPAIN",
	},
	{
		"platform_id": 19,
		"name": "SWEDEN",
	},
	{
		"platform_id": 21,
		"name": "IRELAND",
	},
	{
		"platform_id": 35,
		"name": "AUSTRALIA",
	},
	{
		"platform_id": 38,
		"name": "CANADA",
	},
	{
		"platform_id": 41,
		"name": "UNITED ARAB EMIRATES",
	},
	{
		"platform_id": 340,
		"name": "ITALY",
	},
];
